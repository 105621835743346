import './App.less';
import { Button, notification, message} from 'antd';
import copyClipboard from 'copy-to-clipboard';
import { useState, useEffect } from 'react';
import BadBunnyToken from "./hardhat/artifacts/contracts/BadBunnyToken.sol/BadBunnyToken.json"
import { ethers } from 'ethers';
import Decimal from "decimal.js"

let BadBunnyTokenContractAddr = "0xBf06BBe23f9AE23Fb634691520F19c1504f36997"
let USDTaddr = "0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684"

let Mainnet = false;
let connWallet;
let account;
let Provider;
let chainInfo;
let decimal;
let balance;
let BadBunnyTokenContract;
let USDTTokenContract;
let effect;
let eqRefToNumber;
let RefToNumber;
let swapURL;

function App() {
  const [Account, setAccount] = useState("Connect Wallet");
  const [ConnectWalletLoading, setConnectWalletLoading] = useState(false);
  const [GetRefNumberLoading, setGetRefNumberLoading] = useState(false);
  const [Balance, setBalance] = useState("--");
  const [MarketingBalance, setMarketingBalance] = useState("--");
  const [AccountBalances, setAccountBalances] = useState("--")
  const [RefToNumbers, setRefToNumbers] = useState("");
  const [SwapURL, setSwapURL] = useState("")

  useEffect(() => {
    swapURL = "https://pancakeswap.finance/swap?outputCurrency=";
    if(Mainnet){
      swapURL += BadBunnyTokenContractAddr+"&chainId=56";
    }else{
      swapURL += BadBunnyTokenContractAddr+"&chainId=97";
    }
    setSwapURL(swapURL)
  }, [])


  const onCopy = () => {
    if(!connWallet){
      notification.warning({
        message: '请先连接钱包!'
      });
      return
    }
    if(eqRefToNumber){
      notification.warning({
        message: '没有邀请码'
      });
      copyClipboard("");
    }else{
      copyClipboard(RefToNumbers);
      notification.success({
        message: '复制成功！'
      });
    }
    
  }

  const isMetaMaskInstalled = () => {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  const ConnectWallet = async () => {
    try {
      if (isMetaMaskInstalled()) {
        if (connWallet) {
          return
        }
        setConnectWalletLoading(true);
        await GetAccount();
        Provider = new ethers.providers.Web3Provider(window.ethereum);
        BadBunnyTokenContract = new ethers.Contract(BadBunnyTokenContractAddr, BadBunnyToken.abi, Provider.getSigner());
        USDTTokenContract = new ethers.Contract(USDTaddr, BadBunnyToken.abi, Provider.getSigner());
        
        await chainChanged();
        await GetGameInfo();
        setConnectWalletLoading(false);
        Provider.on('block', async (blockNumber) => {
          await GetGameInfo();
        });
        window.ethereum.on('chainChanged', async function () {
          if (connWallet) {
            connWallet = false;
            await ConnectWallet()
            await GetAccount();
          }

        })
        window.ethereum.on('accountsChanged', async function () {
          //if (connWallet) {
          connWallet = false;
          await ConnectWallet()
          await GetAccount();
          //}
        })
      }

    } catch (err) {
      setConnectWalletLoading(false);
      console.log(err)
    }

  }

  const chainChanged = async () => {
    try {
      Provider = new ethers.providers.Web3Provider(window.ethereum);
      chainInfo = await Provider.getNetwork();
      if (Mainnet) {
        if (chainInfo.chainId !== 56) {
          setAccount("Please switch to the ETND main network");
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }],
          });
        } else {
          setAccount(account.substring(0, 10) + '...' + account.substring(33, 42).toString());
        }
      } else {
        if (chainInfo.chainId !== 97) {
          setAccount("Please switch to the ETND network");
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x61' }],
          });
        } else {
          setAccount(account.substring(0, 10) + '...' + account.substring(33, 42).toString());
        }
      }
    } catch (err) {
      Provider = new ethers.providers.Web3Provider(window.ethereum);
      chainInfo = await Provider.getNetwork();

      if (Mainnet) {
        if (chainInfo.chainId !== 56) {
          setAccount("Please switch to the ETND main network");
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }],
          });
        } else {
          setAccount(account.substring(0, 10) + '...' + account.substring(33, 42).toString());
        }
      } else {
        if (chainInfo.chainId !== 97) {
          setAccount("Please switch to the ETND network");
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x61' }],
          });
        } else {
          setAccount(account.substring(0, 10) + '...' + account.substring(33, 42).toString());
        }
      }
      console.log(err)
    }
    connWallet = true;
  }

  const GetAccount = async () => {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' })
        .then((accounts) => {
          account = accounts[0];
        }).catch((error) => {
          console.error(error)
        });
    } catch (err) {
      console.log(err)
    }

  }

  const GetGameInfo = async() =>{
    try {
      decimal = await BadBunnyTokenContract.decimals();
      balance = await BadBunnyTokenContract.balanceOf(account);
      let MarketingAddr = await BadBunnyTokenContract.MarketingAddr();
      let AccountBalances = await BadBunnyTokenContract.balanceOf(MarketingAddr);
      let USDTdecimal = await USDTTokenContract.decimals();
      let MarketingUSDTBalance = await USDTTokenContract.balanceOf(MarketingAddr);
      let MarketingUSDTBalances = (new Decimal(MarketingUSDTBalance.toString()).div(new Decimal(10).pow(USDTdecimal.toString()))).toFixed(6) 
      effect = new Decimal(AccountBalances.toString()).greaterThanOrEqualTo(new Decimal(100).mul(new Decimal(10).pow(decimal.toString())))
      RefToNumber = await BadBunnyTokenContract.RefToNumber(account);
      eqRefToNumber = new Decimal(RefToNumber.toString()).equals(new Decimal(0))
      
      if(!eqRefToNumber){
        setRefToNumbers(RefToNumber.toString().padStart(6, "0"))
      }
      setMarketingBalance(MarketingUSDTBalances)


      Provider.getBalance(account).then((balancess) => {
        
        let AccountBalances = ethers.utils.formatEther(balancess);
    
        setAccountBalances(AccountBalances.substring(0,8))
      });
      //setMarketingBalance((new Decimal(MarketingBalances.toString()).div(new Decimal(10).pow(18))).toFixed(6))
      setBalance((new Decimal(balance.toString()).div(new Decimal(10).pow(decimal.toString()))).toFixed(6))
      
    }catch (err) {
      console.log(err)
    }
  }

  const GtRefNumber = async() =>{
    try {
      if(!connWallet){
        notification.warning({
          message: '请先连接钱包!'
        });
        return
      }
      setGetRefNumberLoading(true);
      if (!eqRefToNumber){
        message.info("已经拥有了邀请码，无需再次申请!")
        setGetRefNumberLoading(false);
        return 
      }

      if(!effect){
        message.warning("你没有满足条件来领取邀请码!")
      }else{
        let tx = await BadBunnyTokenContract.getRefNumber();
        await tx.wait()
      }
      setGetRefNumberLoading(false);
      await GetGameInfo()
    }catch (err) {
      setGetRefNumberLoading(false);
      console.log(err)
    }
  }

  return (
    <div className="w-100 h-100 flex-vertical">
      <header className="flex-header flex-item align-items-center px-4 py-3">
        <div className="flex-label logo-box flex-center-center p-2">
          <img src={require('./assets/img/logo.png')} alt="logo" className="logo" />
        </div>
        <div className="flex-content d-flex justify-content-end align-items-center">
          <div className="d-flex align-items-center text-title menu-list">
            <a href="#dashboard">
              <div>Home</div>
            </a>
            <a href="#referral" className="ml-4">
              <div>Referral</div>
            </a>
            <a href="#squid" className="ml-4">
              <div>Squid Game</div>
            </a>
            <a href={SwapURL} target="_search" className="ml-4">
              <div>SWAP</div>
            </a>
            <a href="#" className="ml-4">
              <div>DOCS</div>
            </a>
          </div>
          <div className="ml-4">
            <Button type="primary" danger size="large" shape="round" onClick={ConnectWallet} loading={ConnectWalletLoading}>{Account}</Button>
          </div>
        </div>
      </header>
      <article className="flex-content slim-scroll">
        <main>
          {/* <section className="banner-box position-relative overflow-hidden">
            <div className="banner-content">
              <img src={require('./assets/img/banner.png')} alt="banner" className="w-100"></img>
              <div className="w-100 content text-center px-5">
                <h1 className="text-max text-max color-primary">BadBunny</h1>
                <h1 className="text-max">is ultra-exclusive NFT&Token project</h1>
              </div>
            </div>
            <div className="bg bg1" />
            <div className="bg bg2" />
          </section> */}
          <section id="dashboard" className="balance-box px-6 mt-5">
            <div className="balance-content px-4 py-5 text-main flex-center-center round-md">
              <div>
                <div className="d-flex align-items-center">
                  <img className="icon mr-3" src={require('./assets/img/logo.png')} alt="logo" />
                  <div className="des">Your $BBY in wallet</div>
                  <input readOnly value={Balance} />
                </div>
                <div className="d-flex align-items-center mt-5">
                  <svg t="1666863395269" className="mr-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4265" width="2rem" height="2rem"><path d="M511.85 511.04m-447.5 0a447.5 447.5 0 1 0 895 0 447.5 447.5 0 1 0-895 0Z" fill="#F3BA2F" p-id="4266"></path><path d="M431.856 511.037l79.98-79.98 79.98 79.98-79.98 79.98z" fill="#FFFFFF" p-id="4267"></path><path d="M431.86 248.71L300.7 379.87l79.98 79.99 131.17-131.17 131.16 131.17 79.98-79.99-131.16-131.16-79.98-79.98zM591.83 773.37L722.99 642.2l-79.98-79.98-131.16 131.16-131.17-131.16-79.98 79.98 131.16 131.17 79.99 79.98zM169.528 511.036l79.98-79.98 79.98 79.98-79.98 79.98zM694.184 511.038l79.98-79.98 79.98 79.98-79.98 79.98z" fill="#FFFFFF" p-id="4268"></path></svg>
                  <div className="des">Your BNB in wallet</div>
                  <input readOnly value={AccountBalances} />
                </div>
              </div>
              <div className="img-wrapper pl-6">
                <div className="img-box p-2">
                  <div className="img-content p-3-5">
                    <img className="w-100" src={require('./assets/img/join.png')} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="referral" className="invite-box px-6 mt-session">
            <div className="text-center mb-5">
              <h1 className="text-max">
                <span>Invite Friends & Earn </span>
                <span className="color-primary">$BBY</span>
              </h1>
            </div>
            <div className="flex-item">
              <div className="w-50">
                <div className="bg-active round-md p-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h1 className="text-ml">01</h1>
                    <h1 className="text-lg color-success-linear flex-center-center">
                      <span className="mr-2">Require</span>
                      <svg t="1666838923441" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7022" width="2rem" height="2rem"><path d="M106.951359 476.000088c23.394826 0 42.358737 18.96391 42.358737 42.35976 0 23.390733-18.96391 42.357713-42.358737 42.357713s-42.358737-18.96698-42.358737-42.357713C64.592622 494.963998 83.556533 476.000088 106.951359 476.000088zM274.258852 476.000088c23.394826 0 42.35976 18.96391 42.35976 42.35976 0 23.390733-18.964934 42.357713-42.35976 42.357713s-42.358737-18.96698-42.358737-42.357713C231.900116 494.963998 250.864026 476.000088 274.258852 476.000088zM441.566346 476.000088c23.394826 0 42.358737 18.96391 42.358737 42.35976 0 23.390733-18.96391 42.357713-42.358737 42.357713s-42.35976-18.96698-42.35976-42.357713C399.207609 494.963998 418.17152 476.000088 441.566346 476.000088zM608.874863 476.000088c23.393803 0 42.35976 18.96391 42.35976 42.35976 0 23.390733-18.964934 42.357713-42.35976 42.357713s-42.35976-18.96698-42.35976-42.357713C566.515103 494.963998 585.480037 476.000088 608.874863 476.000088zM952.288237 489.278509 688.470689 225.459938c-12.095479-12.089339-35.304064-8.487302-51.842739 8.057513-16.537651 16.538675-20.148899 39.753399-8.05649 51.845809l226.63981 226.641857L628.5776 738.641857c-12.092409 12.087293-8.481162 35.310204 8.05649 51.839669 16.538675 16.540721 39.747259 20.146852 51.842739 8.065699l263.817548-263.820618c5.715165-5.708002 7.880481-13.919011 6.875594-22.721491C960.168718 503.201613 958.003402 494.998791 952.288237 489.278509z" p-id="7023" fill="#6dbff3"></path></svg>
                    </h1>
                  </div>
                  <div className="text-main mt-4">
                    <div>Make sure you are connected to your wallet to proceed</div>
                    <div>Receive your exclusive invitation code (hold at least 100 $BBY)</div>
                  </div>
                </div>
                <div className="bg-active round-md p-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h1 className="text-ml">02</h1>
                    <h1 className="text-lg color-success-linear flex-center-center">
                      <span className="mr-2">Recommend</span>
                      <svg t="1666838923441" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7022" width="2rem" height="2rem"><path d="M106.951359 476.000088c23.394826 0 42.358737 18.96391 42.358737 42.35976 0 23.390733-18.96391 42.357713-42.358737 42.357713s-42.358737-18.96698-42.358737-42.357713C64.592622 494.963998 83.556533 476.000088 106.951359 476.000088zM274.258852 476.000088c23.394826 0 42.35976 18.96391 42.35976 42.35976 0 23.390733-18.964934 42.357713-42.35976 42.357713s-42.358737-18.96698-42.358737-42.357713C231.900116 494.963998 250.864026 476.000088 274.258852 476.000088zM441.566346 476.000088c23.394826 0 42.358737 18.96391 42.358737 42.35976 0 23.390733-18.96391 42.357713-42.358737 42.357713s-42.35976-18.96698-42.35976-42.357713C399.207609 494.963998 418.17152 476.000088 441.566346 476.000088zM608.874863 476.000088c23.393803 0 42.35976 18.96391 42.35976 42.35976 0 23.390733-18.964934 42.357713-42.35976 42.357713s-42.35976-18.96698-42.35976-42.357713C566.515103 494.963998 585.480037 476.000088 608.874863 476.000088zM952.288237 489.278509 688.470689 225.459938c-12.095479-12.089339-35.304064-8.487302-51.842739 8.057513-16.537651 16.538675-20.148899 39.753399-8.05649 51.845809l226.63981 226.641857L628.5776 738.641857c-12.092409 12.087293-8.481162 35.310204 8.05649 51.839669 16.538675 16.540721 39.747259 20.146852 51.842739 8.065699l263.817548-263.820618c5.715165-5.708002 7.880481-13.919011 6.875594-22.721491C960.168718 503.201613 958.003402 494.998791 952.288237 489.278509z" p-id="7023" fill="#6dbff3"></path></svg>
                    </h1>
                  </div>
                  <div className="text-main mt-4">
                    <div>When the decimal place of the number of purchased tokens is your referralcode</div>
                    <div>Referrers and referees will receive an additional 3% tokens</div>
                  </div>
                </div>
                <div className="bg-active round-md p-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h1 className="text-ml">03</h1>
                    <h1 className="text-lg color-success-linear flex-center-center">
                      <span className="mr-2">Example</span>
                      <svg t="1666838923441" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7022" width="2rem" height="2rem"><path d="M106.951359 476.000088c23.394826 0 42.358737 18.96391 42.358737 42.35976 0 23.390733-18.96391 42.357713-42.358737 42.357713s-42.358737-18.96698-42.358737-42.357713C64.592622 494.963998 83.556533 476.000088 106.951359 476.000088zM274.258852 476.000088c23.394826 0 42.35976 18.96391 42.35976 42.35976 0 23.390733-18.964934 42.357713-42.35976 42.357713s-42.358737-18.96698-42.358737-42.357713C231.900116 494.963998 250.864026 476.000088 274.258852 476.000088zM441.566346 476.000088c23.394826 0 42.358737 18.96391 42.358737 42.35976 0 23.390733-18.96391 42.357713-42.358737 42.357713s-42.35976-18.96698-42.35976-42.357713C399.207609 494.963998 418.17152 476.000088 441.566346 476.000088zM608.874863 476.000088c23.393803 0 42.35976 18.96391 42.35976 42.35976 0 23.390733-18.964934 42.357713-42.35976 42.357713s-42.35976-18.96698-42.35976-42.357713C566.515103 494.963998 585.480037 476.000088 608.874863 476.000088zM952.288237 489.278509 688.470689 225.459938c-12.095479-12.089339-35.304064-8.487302-51.842739 8.057513-16.537651 16.538675-20.148899 39.753399-8.05649 51.845809l226.63981 226.641857L628.5776 738.641857c-12.092409 12.087293-8.481162 35.310204 8.05649 51.839669 16.538675 16.540721 39.747259 20.146852 51.842739 8.065699l263.817548-263.820618c5.715165-5.708002 7.880481-13.919011 6.875594-22.721491C960.168718 503.201613 958.003402 494.998791 952.288237 489.278509z" p-id="7023" fill="#6dbff3"></path></svg>
                    </h1>
                  </div>
                  <div className="text-main mt-4">
                    <div>A referral code is 123456. B buys tokens with a quantity of 100.123456 $BBY .After deducting buy tax A and B will get extra 3 $BBY</div>
                  </div>
                </div>
              </div>
              <div className="w-50 ml-4 text-center">
                <div className="bg-active round-md p-4">
                  <div>
                    <Button size="large" shape="round" type="primary">Referral Code</Button>
                  </div>
                  <div className="text-plus my-5">
                    <div>Looks like you don’t have a referral code yet.</div>
                    <div>Create one now and start earning $BBY</div>
                  </div>
                  <div className="mt-4">
                    {
                      Account.includes('...') ? 
                      <Button size="large" type="primary" danger shape="round" loading={GetRefNumberLoading} onClick={GtRefNumber}>申领邀请码</Button>
                      :
                      <Button size="large" type="primary" danger shape="round" loading={ConnectWalletLoading} onClick={ConnectWallet}>{Account}</Button>
                    }
                  </div>
                </div>
                <div className="bg-active round-md p-4 mt-4 flex-item align-items-center">
                  <div className="flex-content input-box px-5 overflow-hidden">
                    <input className="copy-input" placeholder={RefToNumbers} readOnly={true} />
                  </div>
                  <div className="flex-label ml-4">
                    <Button size="large" type="primary" danger shape="round" disabled={!RefToNumbers} onClick={onCopy}>Copy</Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section id="referral" className="recommend-box px-6">
            <div className="recommend-content">
              <div className="text-center mb-5">
                <h1 className="text-max">RECOMMEND</h1>
                <div className="text-title mt-4">When you buy at least 100 tokens, Generate invitation code through our DAPP Anyone who buys an amount of tokens with your code after the decimal will earn a 3% reward, and you also will be rewarded the same amount of tokens.</div>
              </div>
              <div className="content-box px-5 round-md">
                <div className="input-box flex-item align-items-center px-5">
                  <div className="flex-content flex-item py-4 pr-4">
                    <input className="copy-input flex-content" data-clipboard-text="这里是需要复制的文本" placeholder={RefToNumbers} readOnly={true} />
                  </div>
                  <Button className="flex-label ml-5" size="large" type="primary" danger shape="round" onClick={onCopy}>复制</Button>
                  <Button className="flex-label ml-5" size="large" type="primary" danger shape="round" loading={GetRefNumberLoading} onClick={GtRefNumber}>申领邀请码</Button>
                </div>
              </div>
            </div>
          </section> */}
          <section id="squid" className="squid-box mt-session px-6">
            <div className="text-center mb-5">
              <h1 className="text-max">
                <span>Squid</span>
                <span className="color-success ml-4">Game</span>
              </h1>
              <div className="text-title mt-4">To achieve great wealth, perseverance is usually required. We set up a special wallet to reward those admirable holders. The Squid Game Wallet charges 6% sales tax and automatically swap to USDT. if no one buys $BBY for more than 30 minutes, the last buyers received all Squid Game Wallet’s funds. At the same time sales tax is reduced to 0%(Minimum purchase $30 of value)</div>
            </div>
            <div className="flex-item squid-content">
              <div className="squid-item flex-content no-overflow">
                <div className="top px-4 round-sm flex-center-center position-relative">
                  <div className="title text-title">BSC</div>
                  <div className="content text-center">
                    <div className="text-alert">Total Balance</div>
                    <div className="text-title text-bold">${MarketingBalance}</div>
                  </div>
                  <div className="position w-100 px-4">
                    <div className="position-content round-sm"></div>
                  </div>
                </div>
              </div>
              <div className="squid-item flex-content no-overflow mx-4">
                <div className="top px-4 round-sm flex-center-center position-relative">
                  <div className="title text-title">Ethereum</div>
                  <div className="content text-center">
                    <div className="text-alert">Total Balance</div>
                    <div className="text-title text-bold">NA</div>
                  </div>
                  <div className="position w-100 px-4">
                    <div className="position-content round-sm"></div>
                  </div>
                </div>
              </div>
              <div className="squid-item flex-content no-overflow">
                <div className="top px-4 round-sm flex-center-center position-relative">
                  <div className="title text-title">Avalanche</div>
                  <div className="content text-center">
                    <div className="text-alert">Total Balance</div>
                    <div className="text-title text-bold">NA</div>
                  </div>
                  <div className="position w-100 px-4">
                    <div className="position-content round-sm"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer>
          <div className="logo-content d-flex flex-column align-items-center justify-content-center px-6">
            <div>
              <div className="img-wrapper">
                <div className="img-box p-2">
                  <img src={require('./assets/img/logo.png')} alt="" />
                </div>
              </div>
            </div>
            <h1 className="text-max mt-4">
              <span>JOIN</span>
              <span className="color-primary mx-4">BAD</span>
              <span>BUNNY</span>
            </h1>
            <h1 className="text-normal">Become a member of the strongest and fast growing NFT&Token community.</h1>
            <div className="mt-5">
              <a className="d-inline-block round-lg">
                <Button type="primary" shape="round">
                  <svg className="mx-4" t="1666834137917" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2229" width="2rem" height="2rem"><path d="M928 254.3c-30.6 13.2-63.9 22.7-98.2 26.4 35.4-21.1 62.3-54.4 75-94-32.7 19.5-69.7 33.8-108.2 41.2C765.4 194.6 721.1 174 672 174c-94.5 0-170.5 76.6-170.5 170.6 0 13.2 1.6 26.4 4.2 39.1-141.5-7.4-267.7-75-351.6-178.5-14.8 25.4-23.2 54.4-23.2 86.1 0 59.2 30.1 111.4 76 142.1-28-1.1-54.4-9-77.1-21.7v2.1c0 82.9 58.6 151.6 136.7 167.4-14.3 3.7-29.6 5.8-44.9 5.8-11.1 0-21.6-1.1-32.2-2.6C211 652 273.9 701.1 348.8 702.7c-58.6 45.9-132 72.9-211.7 72.9-14.3 0-27.5-0.5-41.2-2.1C171.5 822 261.2 850 357.8 850 671.4 850 843 590.2 843 364.7c0-7.4 0-14.8-0.5-22.2 33.2-24.3 62.3-54.4 85.5-88.2z" p-id="2230" fill="#ffffff"></path></svg>
                </Button>
              </a>
              <a className="d-inline-block round-lg ml-3">
                <Button type="primary" shape="round">
                  <svg className="mx-4" t="1666859435351" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2600" width="2rem" height="2rem"><path d="M981.333333 414.293333a362.666667 362.666667 0 0 0-38.826666-176.213333 124.586667 124.586667 0 0 0-73.386667-42.666667A3343.36 3343.36 0 0 0 512 182.186667a3347.2 3347.2 0 0 0-355.84 12.8 122.453333 122.453333 0 0 0-62.293333 31.573333c-38.4 35.413333-42.666667 96-46.933334 147.2a2060.373333 2060.373333 0 0 0 0 276.48 407.466667 407.466667 0 0 0 12.8 85.333333 133.973333 133.973333 0 0 0 30.293334 58.026667 122.026667 122.026667 0 0 0 63.573333 33.28 1927.68 1927.68 0 0 0 277.333333 14.08c149.333333 2.133333 280.32 0 435.2-11.946667a122.88 122.88 0 0 0 65.28-33.28 106.24 106.24 0 0 0 26.026667-42.666666 451.413333 451.413333 0 0 0 22.186667-145.066667c1.706667-23.893333 1.706667-168.106667 1.706666-193.706667zM415.573333 633.6V369.493333l252.586667 132.693334c-70.826667 39.253333-164.266667 83.626667-252.586667 131.413333z" p-id="2601" fill="#ffffff"></path></svg>
                </Button>
              </a>
              <a className="d-inline-block round-lg ml-3">
                <Button type="primary" shape="round">
                  <svg t="1666863115163" className="mx-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3635" width="2rem" height="2rem"><path d="M957.4 197.2l-135.2 637.6c-10.2 45-36.8 56.2-74.6 35l-206-151.8-99.4 95.6c-11 11-20.2 20.2-41.4 20.2l14.8-209.8 381.8-345c16.6-14.8-3.6-23-25.8-8.2L299.6 568 96.4 504.4c-44.2-13.8-45-44.2 9.2-65.4L900.4 132.8c36.8-13.8 69 8.2 57 64.4z" p-id="3636" fill="#ffffff"></path></svg>
                </Button>
              </a>
            </div>
          </div>
          <div className="foot-content d-flex justify-content-between align-items-center px-4 py-3 mt-5">
            <div>
              <img className="logo" src={require('./assets/img/logo.png')} alt="logo" />
            </div>
            <div className="d-flex align-items-center">
              <a>Privacy Poicy</a>
              <a className="ml-3">Terms&Condition Poicy</a>
              <a className="ml-3">Cookies</a>
            </div>
          </div>
        </footer>
      </article>
    </div>
  );
}

export default App;
